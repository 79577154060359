/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';

import Trafic from './Trafic';
import Webcam from './Webcam';
import Wallpaper from './Wallpaper';
import Train from './Train';
import Home, { Weather, Graph } from './Weather';
import Clock from './Clock';

const credentials = require('./crendential.json');

function App() {
	const [timer, setTimer] = useState(0);
	const [trains, setTrains] = useState([]);
	const [error, setError] = useState('');
	const [weatherData, setWeatherData] = useState(null);

	const fetchData = useCallback(async (url, setData, handleError) => {
		try {
			const response = await fetch(url);
			const data = await response.json();
			setData(data);
		} catch (error) {
			handleError && handleError(error);
		}
	}, []);

	const getWeather = useCallback(() => {
		const url = `https://api.openweathermap.org/data/2.5/forecast?lat=48.8333469&lon=1.9511243&appid=${credentials.API_weather}&units=metric&lang=fr`;
		fetchData(url, data => {
			setWeatherData(data);
		}, () => setWeatherData(null));
	}, [fetchData]);

	const getTrain = useCallback(() => {
		const url = 'https://navika.fr/schedules/IDFM:64199?l=IDFM:C01736';
		fetchData(url, data => {
			if (data.error) {
				setError(data.error === '200' ? 'Récupération des trains impossible.' : data.error_message);
			} else {
				setTrains(data.schedules);
			}
		}, () => setError('Récupération des trains impossible.'));
	}, [fetchData]);

	useEffect(() => {
		getTrain();
		getWeather();

		const intervalTimer = setInterval(() => setTimer(timer => (timer > 1000 * 55 ? 0 : timer + 1000)), 1000);
		const intervalTrain = setInterval(getTrain, 1000 * 60 * 2);
		const intervalWeather = setInterval(getWeather, 1000 * 60 * 1);

		return () => {
			clearInterval(intervalTimer);
			clearInterval(intervalTrain);
			clearInterval(intervalWeather);
		};
	}, [getTrain, getWeather]);

	const classes = () => {
		if (timer < 1000 * 10) return 'home';
		if (timer < 1000 * 25) return 'home graph';
		// if (timer < 1000 * 40) return 'home weather';
	};

	return (
		<div className={classes()}>
			<Clock />
			{timer < 1000 * 25 ? (
				<>
					<Wallpaper weatherData={weatherData} />
					<Home weatherData={weatherData} />
					<Graph weatherData={weatherData} />
					{/* <Weather weatherData={weatherData} /> */}
				</>
			) : timer < 1000 * 40 ? (
				<>
					<Train trains={trains} error={error} />
					<Trafic trafic={trains} />
				</>
			) : (
				<Webcam />
			)}
		</div>
	);
}

export default App;
